import Vue from "vue";
import Vuex from "vuex";
import service from "./services/product-service";

import auth from "@/store/modules/auth";
import roles from "@/store/modules/roles-module";
import alerts from "./modules/alerts-module";
import permissions from "./modules/permissions-module";
import meta from "./modules/meta-module";
import users from "./modules/users-moduel";
import dashboard from "./modules/dashboard-module";
import locations from "./modules/locations-module";
import iotDeviceMakes from "./modules/iot-device-makes-module";
import iotDeviceModels from "./modules/iot-device-models-module";
import iotDevices from "./modules/iot-devices-module";
import oemclients from "./modules/oem-clients-moduel";
import dataSims from "./modules/data-sims-module";
import mdMakes from "./modules/md-makes-module";
import vehicleTypes from "./modules/vehicle-types-module";
import batteryTypes from "./modules/battery-types-module";
import motorTypes from "./modules/motor-types-module";
import mdModels from "./modules/md-models-module";
import batteryMakes from "./modules/battery-makes-module";
import batteryModels from "./modules/battery-models-module";
import batterries from "./modules/batterries-module";
import drivingStaff from "./modules/driving-staff-module";
import staffAssignment from "./modules/staff-assignment-module";
import oemCustomers from "./modules/oem-customers-module";
import vehicles from "./modules/vehicles-module";
import customerVehicles from "./modules/customer-vehicles-module";
import supportTickets from "./modules/support-tickets-module";
import telematicDetails from "./modules/telematic-details-module";
import flespiStream from "./modules/flespi-streams-module";
import flespiPlugin from "./modules/flespi-plugins-module";
import tableHeaders from "./modules/table-headers-module";
import vehicleChargeSession from "./modules/vehicle-charge-session-module";
import vehicleTrips from "./modules/vehicle-trips-module";
import oemBusiness from "./modules/oemB2b-module";
import tenantsManagement from "./modules/tenants-management-module";
import tenantsConfigurations from "./modules/tenant-configurations-module";
import products from "./modules/products-module";
import batteryDiagnostics from "./modules/battery-diagnostics-module";
import flespiCommands from "./modules/flespi-commands-module";
import comparison from "./modules/comparison-module ";
import flespiCalculator from "./modules/flespi-calculator-module";
import truckistanRoute from "./modules/truckistan-route-module";
import routeListing from "./modules/route-listing-module";
import routePlaning from "./modules/route-planing-module";
import csmsLocations from "./modules/csms-locations-module";
import csmsRates from "./modules/csms-rates-module";
import csmsChargeStations from "./modules/csms-charge-stations-module";
import csmsBookings from "./modules/csms-bookings-module";
import journeyReports from "./modules/journey-reports-module";
import dailyMileageReports from "./modules/daily-mileage-reports-module";
import maintenanceLogs from "./modules/maintenance-logs-module";
import upcomingMaintenance from "./modules/upcoming-maintenance-module";
import generativeAI from "./modules/generative-ai-module";
import redirect from "./modules/redirect-module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    roles,
    alerts,
    permissions,
    meta,
    users,
    dashboard,
    locations,
    iotDeviceModels,
    oemclients,
    dataSims,
    iotDeviceMakes,
    mdMakes,
    vehicleTypes,
    motorTypes,
    batteryTypes,
    mdModels,
    iotDevices,
    batteryMakes,
    batteryModels,
    batterries,
    drivingStaff,
    staffAssignment,
    oemCustomers,
    vehicles,
    customerVehicles,
    supportTickets,
    telematicDetails,
    flespiStream,
    flespiPlugin,
    tableHeaders,
    vehicleChargeSession,
    vehicleTrips,
    oemBusiness,
    tenantsManagement,
    tenantsConfigurations,
    products,
    batteryDiagnostics,
    flespiCommands,
    comparison,
    flespiCalculator,
    truckistanRoute,
    routeListing,
    routePlaning,
    csmsLocations,
    csmsRates,
    csmsChargeStations,
    csmsBookings,
    journeyReports,
    dailyMileageReports,
    maintenanceLogs,
    upcomingMaintenance,
    generativeAI,
    redirect,
  },
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage:
      "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg",
    drawer: null,
    allProducts: [],
    countries: [],
    states: [],
    cities: [],

    //
    codeList: ["+1", "+92"],
    tzList: ["America/Martinique", "Asia/Karachi"],
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_ALL_PRODUCTS(state, payload) {
      state.allProducts = payload;
    },
    //
    SET_COUNTRIES(state, payload) {
      state.countries = payload;
    },
    SET_STATES(state, payload) {
      state.states = payload;
    },
    SET_CITIES(state, payload) {
      state.cities = payload;
    },
  },
  actions: {
    setAllProducts: (store, params) => {
      return service.list(params).then((data) => {
        store.commit("SET_ALL_PRODUCTS", data.body.data);
      });
    },
    setCountries: (store, params) => {
      return service.countries(params).then((data) => {
        store.commit("SET_COUNTRIES", data.countries);
      });
    },
    setStates: (store, params) => {
      return service.states(params).then((data) => {
        store.commit("SET_STATES", data.states);
      });
    },
    setCities: (store, params) => {
      return service.cities(params).then((data) => {
        store.commit("SET_CITIES", data.cities);
      });
    },
  },
  getters: {
    getAllProducts(state) {
      return state.allProducts;
    },
    getTZList(state) {
      return state.tzList;
    },
    getCountryCode(state) {
      return state.codeList;
    },
    getCountries(state) {
      return state.countries;
    },
    getStates(state) {
      return state.states;
    },
    getCities(state) {
      return state.cities;
    },
  },
});
